
const filterCatalogCards = (filterSizes) => {
  const itemCards = document.querySelectorAll('.item-card')

  if (filterSizes.length > 0) {
    let has_items = false
    
    itemCards.forEach(item => {
      let found = false
      let item_available_sizes = item.dataset.available_sizes
      filterSizes.forEach(size => {
        if (item_available_sizes.includes(size)) {
          found = true
          has_items = true
        }
      })
      if (found) item.style.display = 'flex'
      else item.style.display = 'none'
    })

    if (!has_items) {
      // todo show nothing
      // but its a bug, because we show only sizes in filter which we have in catalog cards feeds
    }
    
  } else {
    itemCards.forEach(item => item.style.display = 'flex' )
  }
}

export {
  filterCatalogCards,
}