import NiceSelect from 'nice-select2'

// import '../../styles/nice-select2.scss'

import {
  addItemToCart, checkIfItemAlreadyInCart,
} from './cart'
import { getCookie } from './fetch'
import { showNotification } from './notification'
import { filterCatalogCards } from './filter'

import { createPhoneMask } from './masks'

var currentPopup = ''
var catalogAddToCartData = {
  itemPK: null,
  itemCC: null,
  selectedSize: null,
}
var catalogBuyInOneData = {
  itemPK: null,
  itemCC: null,
  selectedSize: null,
  niceSelect: null,
}
var catalogNewReviewData = {
  itemPK: null,
  selectedRating: 0,
}

var mobileFilterSelectedSizes = []

var buyInOnePopupPhoneMask = null

window.document.addEventListener('DOMContentLoaded', () => {

  const phoneElement = document.getElementById('popup-buy-in-one-phone')
  buyInOnePopupPhoneMask = createPhoneMask(phoneElement)

})

window.document.buyInOneSizesSelectChanged = (e) => {

}

const showPopup = (type, data) => {

  switch(type) {
    case 'catalog-add-to-cart': {
      // TODO:
      // if sizes_str is empty or not json valid ???

      const sizes = data.sizes
      const sizeBoxes = document.querySelectorAll('.size-in-a-box')

      resetPopup(type)

      catalogAddToCartData.itemPK = data.itemPK
      catalogAddToCartData.itemCC = data.itemCC

      sizeBoxes.forEach(el => {
        el.style.display = 'none'
      })
      
      sizes.forEach(size => {
        sizeBoxes.forEach(el => {
          if (size.stocks > 0 && el.dataset.size == size.size) 
            el.style.display = 'flex'
        })
      })
      
      currentPopup = 'catalog-add-to-cart'
      document.getElementById(currentPopup).style.display = 'flex'

      break
    }
    case 'buy-in-one': {

      catalogBuyInOneData.itemPK = data.itemPK
      catalogBuyInOneData.itemCC = data.itemCC

      document.getElementById('buy-in-one-popup-img').src = data.itemImgUrl
      document.getElementById('buy-in-one-popup-name').innerHTML = data.itemName
      document.getElementById('buy-in-one-popup-vendor-code').innerHTML = '[АРТ ' + data.itemVendorCode + ']'

      let sizesHTML = '<option value="null"> Размер: выбрать </option>'

      data.sizes.forEach(el => {
        sizesHTML += `<option value="${el.size}" ${(el.stocks <= 0 ? 'disabled' : '')}> Размер: ${el.size} ${(el.stocks <= 0 ? '| Нет в наличии' : '')}</option>`
      })

      document.getElementById('buy-in-one-sizes-select').innerHTML = sizesHTML

      if (catalogBuyInOneData.niceSelect) catalogBuyInOneData.niceSelect.destroy()
      catalogBuyInOneData.niceSelect = new NiceSelect(document.getElementById('buy-in-one-sizes-select'), {
        searchable: false
      })

      currentPopup = 'buy-in-one'
      document.getElementById(currentPopup).style.display = 'flex'

      break
    }
    case 'size-chart': {      
      fetch('/api/v1/size-chart/', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify({
          pk: data.itemPK,
        })
      })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data.status == 'ok') {
          document.getElementById('size-chart__c').innerHTML = data.html

          currentPopup = 'size-chart'
          document.getElementById(currentPopup).style.display = 'flex'
        }
      })

      break
    }
    case 'new-review': {
      catalogNewReviewData.itemPK = data.itemPK

      document.getElementById('new-review-item-name').innerHTML = data.itemName
 
      currentPopup = 'new-review'
      document.getElementById(currentPopup).style.display = 'flex'

      break
    }
    case 'catalog-mobile-filter': {
      const sizes = JSON.parse(data.available_sizes.replaceAll('\'', '"'))
      const sizeBoxes = document.querySelectorAll('.mobile-filter__c__option')

      sizeBoxes.forEach(el => {
        el.style.display = 'none'
      })
      
      sizes.forEach(size => {
        sizeBoxes.forEach(el => {
          if (el.dataset.size == size) 
            el.style.display = 'flex'
        })
      })
      
      currentPopup = 'catalog-mobile-filter'
      document.getElementById(currentPopup).style.display = 'flex'

      break
    }
    
    default:
      return;
  }
}

const resetPopup = (type) => {
  switch(type) {
    case 'catalog-add-to-cart':

      currentPopup = null

      catalogAddToCartData.itemPK = null
      catalogAddToCartData.itemCC = null
      catalogAddToCartData.selectedSize = null

      resetSizesInBoxes()
      resetCartBtn()

      break

    case 'catalog-mobile-filter':

      currentPopup = null

      resetMobileFilterSizes()

      break

  }

}

const resetSizesInBoxes = () => {
  const sizeBoxes = document.querySelectorAll('.size-in-a-box')
  
  sizeBoxes.forEach(el => el.classList.remove('size-in-a-box--active'))
}
const resetMobileFilterSizes = () => {
  mobileFilterSelectedSizes = []

  const sizeBoxes = document.querySelectorAll('.mobile-filter-checkbox')
  
  sizeBoxes.forEach(el => el.checked = false)
}

window.document.resetMobileFilterSizes = () => {
  resetMobileFilterSizes()
}


window.document.popupClose = () => {
  document.getElementById(currentPopup).style.display = 'none'
}
window.document.outsidePopupClick = (e) => {
  if (e.target != e.currentTarget) return;

  document.getElementById(currentPopup).style.display = 'none'
}
window.document.sizeInABoxClick = (e) => {
  resetSizesInBoxes()

  e.currentTarget.classList.add('size-in-a-box--active')

  catalogAddToCartData.selectedSize = e.currentTarget.dataset.size

  switchCartButtonIfNeed(catalogAddToCartData.itemPK, catalogAddToCartData.itemCC, catalogAddToCartData.selectedSize)

}
//
window.document.catalogAddToCartBtnClick = (e) => {

  if (catalogAddToCartData.selectedSize == null) {
    showNotification({ text: 'Пожалуйста выберите размер', type: 'info' })
    return;
  }
  
  const newItem = {
    pk: catalogAddToCartData.itemPK,
    cc: catalogAddToCartData.itemCC,
    size: catalogAddToCartData.selectedSize,
    count: 1,
  }

  let itemExists = checkIfItemAlreadyInCart(newItem)
  
  if (itemExists) {
    showNotification({
      type: 'info',
      text: 'Вы уже добавили этот товар в корзину. \n Количество и размеры экземпляров можно будет поменять при оформлении заказа',
      duration: 10000,
    })

  } else {
    addItemToCart(newItem)

    //

    window.ym(46695258, 'reachGoal', 'goal-add-to-basket-from-catalog-preview')
    window.ym(46695258, 'reachGoal', 'goal-add-to-basket-common')

    window.gtag('event', 'goal-add-to-basket-from-catalog-preview', {})
    window.gtag('event', 'goal-add-to-basket-common', {})

    window.VK.Retargeting.Event('event_ad-checkout-f-preview')

    //

    showNotification({
      text: 'Товар добавлен в корзину',
      duration: 3000,
    })
    
    switchCartButtonIfNeed(catalogAddToCartData.itemPK, catalogAddToCartData.itemCC, catalogAddToCartData.selectedSize)

  }

}
window.document.catalogGoToCartBtnClick = () => {
  window.open('/checkout/', '_blank').focus()
}

const switchCartButtonIfNeed = (itemPK, itemCC, selectedSize) => {
  const addToCartButton = document.getElementById('catalog-add-to-cart-btn')
  const goToCartButton = document.getElementById('catalog-go-to-cart-btn')

  let alreadyExist = checkIfItemAlreadyInCart({
    pk: itemPK,
    cc: itemCC,
    size: selectedSize,
  })

  if (alreadyExist) {
    addToCartButton.style.display = 'none'
    goToCartButton.style.display = 'block'
  } else {
    addToCartButton.style.display = 'block'
    goToCartButton.style.display = 'none'
  }
}
const resetCartBtn = () => { 
  const addToCartButton = document.getElementById('catalog-add-to-cart-btn')
  const goToCartButton = document.getElementById('catalog-go-to-cart-btn')

  addToCartButton.style.display = 'block'
  goToCartButton.style.display = 'none'
  
}

window.document.popupInputOnChange = (event) => {
  validateOneClickInputs(false)
  validateNewReviewInputs(false)
}

window.document.buyInOnePopupButtonClick = (event) => { 
  
  const inputsWithError = validateOneClickInputs(true)
  if (inputsWithError.length != 0) {
    showNotification({ text: 'Пожалуйста заполните обязательные поля', type: 'info' })

    return;
  }

  const selectedSize = document.getElementById('buy-in-one-sizes-select').value

  const cartData = {
    pk: catalogBuyInOneData.itemPK, 
    cc: catalogBuyInOneData.itemCC, 
    selectedSize: selectedSize,
  }

  window.ym(46695258, 'reachGoal', 'goal-buy-in-one-click-sent')

  window.gtag('event', 'goal-buy-in-one-click-sent', {})

  window.VK.Retargeting.Event('lid_buy-in-one-click')
  window.VK.Retargeting.ProductEvent(160310, "purchase", {"business_value" : 2000})


  fetch('/api/v1/leads/new/', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
    },
    body: JSON.stringify({
      type: 1,
      name: buyInOneInputName.value,
      phone: buyInOnePopupPhoneMask.unmaskedValue,
      cart: cartData,
    })
  })
  .then((response) => {
    return response.json()
  })
  .then((data) => {
    if (data.status == 'ok') {
      console.log('ok')
    }
  })

  document.getElementById('buy-in-one-popup-container').innerHTML = 'Мы получили вашу заявку!'

}

const buyInOneInputName = document.getElementById('popup-buy-in-one-name')
const buyInOneInputPhone = document.getElementById('popup-buy-in-one-phone')

const validateOneClickInputs = (showError) => {
  let elementsWithError = []

  if (buyInOneInputName.value.trim() == '') {
    if (showError) buyInOneInputName.classList.add('input--error')
    elementsWithError.push(buyInOneInputName)
  } else {
    buyInOneInputName.classList.remove('input--error')
  }
  if (buyInOneInputPhone.value.trim() == '' || (buyInOnePopupPhoneMask != null && buyInOnePopupPhoneMask.unmaskedValue.length < 11)) {
    if (showError) buyInOneInputPhone.classList.add('input--error')
    elementsWithError.push(buyInOneInputPhone)
  } else {
    buyInOneInputPhone.classList.remove('input--error')
  }

  return elementsWithError
}

/////
/////

const review_name = document.getElementById('popup-new-review-name')
const review_email = document.getElementById('popup-new-review-email')
const review_body = document.getElementById('popup-new-review-body')

const validateNewReviewInputs = (showError) => {
  let elementsWithError = []

  if (review_name.value.trim() == '') {
    if (showError) review_name.classList.add('input--error')
    elementsWithError.push(review_name)
  } else {
    review_name.classList.remove('input--error')
  }
  if (review_email.value.trim() == '') {
    if (showError) review_email.classList.add('input--error')
    elementsWithError.push(review_email)
  } else {
    review_email.classList.remove('input--error')
  }
  if (review_body.value.trim() == '') {
    if (showError) review_body.classList.add('textarea--error')
    elementsWithError.push(review_body)
  } else {
    review_body.classList.remove('textarea--error')
  }

  return elementsWithError
}

window.document.newReviewPopupButtonClick = () => {
  const inputsWithError = validateNewReviewInputs(true)
  if (inputsWithError.length != 0) {
    showNotification({ text: 'Пожалуйста заполните обязательные поля', type: 'info' })

    return;
  }

  if (catalogNewReviewData.selectedRating == 0) {
    showNotification({ text: 'Пожалуйста выберите рейтинг', type: 'info' })
    return;
  }

  fetch(`/api/v1/${catalogNewReviewData.itemPK}/reviews/new/`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': getCookie('csrftoken'),
    },
    body: JSON.stringify({
      name: review_name.value,
      email: review_email.value,
      review_body: review_body.value,
      rating: catalogNewReviewData.selectedRating,
    })
  })
  .then((response) => {
    return response.json()
  })
  .then((data) => {
    if (data.status == 'ok') {
      console.log('ok')
    }
  })

  document.getElementById('new-review-popup-btn-c').innerHTML = 'Спасибо за ваш отзыв!'

  
  

}

window.document.newReviewStarClick = (rating) => {
  for (let i = 1; i <= 5; i++) {
    document.getElementById('new-review-star-' + i).classList.remove('star--active')
  }
  for (let i = 1; i <= rating; i++) {
    document.getElementById('new-review-star-' + i).classList.add('star--active')
  }

  catalogNewReviewData.selectedRating = rating
}

window.document.mobileFilterSizeInABoxClick = (e) => {
  let size = e.currentTarget.dataset.size

  if (e.currentTarget.checked) {
    mobileFilterSelectedSizes.push(size)
  } else {
    mobileFilterSelectedSizes = mobileFilterSelectedSizes.filter(el => el != size)
  }
}

window.document.applyMobileFilter = (e) => {

  document.getElementById(currentPopup).style.display = 'none'

  filterCatalogCards(mobileFilterSelectedSizes)

}



export {
  showPopup,
}