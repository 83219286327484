
const generateUUID = () => {
  var d = new Date().getTime()
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16
      if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d/16)
      } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2/16)
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

const cleanCartData = () => {
  localStorage.setItem('sw4_cart', '[]')
}

const getCartData = () => {
  let sw4_cart = localStorage.getItem('sw4_cart')
  sw4_cart = JSON.parse(sw4_cart)
  if (sw4_cart == null) sw4_cart = []

  return sw4_cart
}

const addItemToCart = (item) => {

  /* OLD SHIT
    id: rowId,
    variant_id: variantId,
    variant_cc: item_cc,
    category: category,
    item_id: item_id,
    count: 1,
    price: variantPrice,
    size: size
  */

  let sw4_cart = localStorage.getItem('sw4_cart')
  sw4_cart = JSON.parse(sw4_cart)
  if (sw4_cart == null) sw4_cart = []
  
  sw4_cart = [
    {
      uuid: generateUUID(),
      ...item,
    },
    ...sw4_cart,
  ]

  localStorage.setItem('sw4_cart', JSON.stringify(sw4_cart))

  updateCartItemsCount()
}

const updateCartItem = (item) => {
  let sw4_cart = localStorage.getItem('sw4_cart')
  sw4_cart = JSON.parse(sw4_cart)

  sw4_cart.forEach(el => {
    if (el.uuid == item.uuid) {
      if (item.size) el.size = item.size
      if (item.count) el.count = item.count
    }
  })

  localStorage.setItem('sw4_cart', JSON.stringify(sw4_cart))
}


const checkIfItemAlreadyInCart = (item) => {
  let sw4_cart = localStorage.getItem('sw4_cart')
  sw4_cart = JSON.parse(sw4_cart)
  if (sw4_cart == null) sw4_cart = []

  let already_exist = false

  if (sw4_cart)
    sw4_cart.forEach((el) => {
      if (el.pk == item.pk && el.cc == item.cc && el.size == item.size)
        already_exist = true
      
    })

  return already_exist
}

const removeItemFromCart = (uuid) => {
  let sw4_cart = localStorage.getItem('sw4_cart')
  sw4_cart = JSON.parse(sw4_cart)

  sw4_cart = sw4_cart.filter(el => el.uuid != uuid)

  localStorage.setItem('sw4_cart', JSON.stringify(sw4_cart))

  return sw4_cart
}


const getCartItemsTotalCount = () => {
  let sw4_cart = localStorage.getItem('sw4_cart')
  sw4_cart = JSON.parse(sw4_cart)
  if (sw4_cart == null) sw4_cart = []

  return sw4_cart.length
}

const updateCartItemsCount = () => {
  const itemsTotalCount = getCartItemsTotalCount()

  try {
    document.getElementById('checkout-count').innerHTML = itemsTotalCount
    document.getElementById('checkout-count-mobile').innerHTML = itemsTotalCount
    document.getElementById('fixed-checkout-count').innerHTML = itemsTotalCount
    document.getElementById('fixed-checkout-count-mobile').innerHTML = itemsTotalCount
  } catch (error) { 
    console.log(error)
  }
}

export {
  cleanCartData,
  getCartData,
  addItemToCart,
  updateCartItem,
  checkIfItemAlreadyInCart,
  removeItemFromCart,
  getCartItemsTotalCount,
  updateCartItemsCount,
}
