import IMask from 'imask'

const createPhoneMask = (inputElement) => {
  return IMask(inputElement, {
    mask: [
      {
        mask: '+{7}(000)000-00-00',
        startsWith: '7',
      },
      {
        mask: '{8}(000)000-00-00',
        startsWith: '8',
      },
    ],
    dispatch: (appended, dynamicMasked) => {
      const number = (dynamicMasked.value + appended).replace(/\D/g,'');
      const found = dynamicMasked.compiledMasks.find(m => number.indexOf(m.startsWith) === 0)

      return found ? found : dynamicMasked.compiledMasks[0];
    }
  })
}

export {
  createPhoneMask,
}