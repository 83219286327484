import classNames from 'classnames';

import Toastify from 'toastify-js'

const showNotification = (noti) => {

  let toastClass = classNames(
    { 'toastify-pink': noti.type == 'success' },
    { 'toastify-blue': noti.type == 'info' },
  )

  if (toastClass == '') {
    toastClass = 'toastify-pink'
  }

  let toast = Toastify({
    text: noti.text,
    duration: noti.duration,
    className: toastClass,
    close: true,
    gravity: 'top', // `top` or `bottom`
    position: 'right', // `left`, `center` or `right`
    onClick: function(e) {
      toast.hideToast()
    }
  }).showToast();
} 

export {
  showNotification,
}